import CellConstants from '../constants/CellConstants.json'
const CARDS_PER_STRIP = 6;
const ROWS_PER_CARD = 3;
const CELLS_PER_ROW = 9;
const NUMBERS_PER_ROW = 5;


const NUMBERS = [
            [1,2,3,4,5,6,7,8,9],
            [10,11,12,13,14,15,16,17,18,19],
            [20,21,22,23,24,25,26,27,28,29],
            [30,31,32,33,34,35,36,37,38,39],
            [40,41,42,43,44,45,46,47,48,49],
            [50,51,52,53,54,55,56,57,58,59],
            [60,61,62,63,64,65,66,67,68,69],
            [70,71,72,73,74,75,76,77,78,79],
            [80,81,82,83,84,85,86,87,88,89,90]
];

function generateAStrip() {
    const strip = new Array(CARDS_PER_STRIP).fill().map(u => {return new Array(ROWS_PER_CARD).fill().map(u => { return new Array(CELLS_PER_ROW).fill().map(u => { return { status: CellConstants.status.unselected, value: CellConstants.value.empty }; })})});

    for (let columnIndex in NUMBERS) {
        for (let numberIndex in NUMBERS[columnIndex]) {
            let numberToAdd = NUMBERS[columnIndex][numberIndex];
            NUMBERS[columnIndex][numberIndex] = '**'

            let numberAdded = false;
            while (!numberAdded) {
                let cardIndex = Math.floor(Math.random() * CARDS_PER_STRIP);
                let rowIndex = Math.floor(Math.random() * ROWS_PER_CARD);
                let cardAndRow = getBestRow(strip, cardIndex, rowIndex, columnIndex);
                let card = strip[cardAndRow.cardIndex];
                let row = card[cardAndRow.rowIndex];
                let numbersInRow = row.filter(cell => cell.value !== CellConstants.value.empty).length;
                let numbersInColumn = countNumbersInColumn(card, columnIndex);
                
                if (numbersInRow < NUMBERS_PER_ROW && row[columnIndex].value === CellConstants.value.empty && (columnIndex > CELLS_PER_ROW-2 || numbersInColumn <= 1)) {
                    row[columnIndex] = { status: CellConstants.status.unselected, value: numberToAdd };
                    numberAdded = true;
                }
            }
        }
    }

    // Set Blank cells in row
    for (let cardIndex in strip) {
        let card = strip[cardIndex];
        for (let rowIndex in card) {
            let row = strip[cardIndex][rowIndex];
            for (let cellIndex in row) {
                if (row[cellIndex].value === CellConstants.value.empty) {
                    row[cellIndex].status = CellConstants.status.disabled;
                    row[cellIndex].value = CellConstants.value.blank;
                }
            }
        }
    }

    for (let cardIndex in strip) {
        let card = strip[cardIndex];
        // console.log(`|_______________________________________________________|`);
        console.log(`| ${card[0][0].value} | ${card[0][1].value} | ${card[0][2].value} | ${card[0][3].value} | ${card[0][4].value} | ${card[0][5].value} | ${card[0][6].value} | ${card[0][7].value} | ${card[0][8].value} |`);
        // console.log(`|_______________________________________________________|`);
        console.log(`| ${card[1][0].value} | ${card[1][1].value} | ${card[1][2].value} | ${card[1][3].value} | ${card[1][4].value} | ${card[1][5].value} | ${card[1][6].value} | ${card[1][7].value} | ${card[1][8].value} |`);
        // console.log(`|_______________________________________________________|`);
        console.log(`| ${card[2][0].value} | ${card[2][1].value} | ${card[2][2].value} | ${card[2][3].value} | ${card[2][4].value} | ${card[2][5].value} | ${card[2][6].value} | ${card[2][7].value} | ${card[2][8].value} |`);
        // console.log(`|_______________________________________________________|`);
        console.log(``);
    }
    
    return strip;
}

function getBestRow(strip, randomCardIndex, randomRowIndex, columnIndex) {
    let cardChosenIndex = randomCardIndex;
    let rowChosenIndex = randomRowIndex;
    for (let cardIndex in strip) {
        let card = strip[cardIndex];
        for (let rowIndex in card) {
            let row = card[rowIndex];
            let rowChosen = strip[cardChosenIndex][rowChosenIndex];
            if (row.filter(cell => cell.value !== CellConstants.value.empty).length < rowChosen.filter(cell => cell.value !== CellConstants.value.empty).length && isTheBestCard(strip, cardIndex, columnIndex)) {
                cardChosenIndex = cardIndex;
                rowChosenIndex = rowIndex;
            }
        }
    }
    return { "cardIndex": cardChosenIndex, "rowIndex": rowChosenIndex };
}

function isTheBestCard(strip, randomCardIndex, columnIndex) {
    let cardChosenIndex = randomCardIndex;
    let numbersInColumn = countNumbersInColumn(strip[cardChosenIndex], columnIndex)
    for (let cardIndex in strip) {
        if (cardIndex !== randomCardIndex &&  countNumbersInColumn(strip[cardIndex], columnIndex) < numbersInColumn) {
            return false;
        } 
    }
    return true;
}

function countNumbersInColumn(card, columnIndex) {
    let numbersCount = 0;
    for (let rowIndex in card) {
        if (card[rowIndex][columnIndex].value !== CellConstants.value.empty) numbersCount++;
    }
    return numbersCount;
}

export { generateAStrip };