import React from 'react';
import { StyledCardWrapper, StyledCardHeader, StyledCard, StyledGrid, StyledGridRow } from './Card.style';
import { Cell } from '../../components';

const Card = ({ cardData }) => {
    const MAX_ROWS = [...Array(3).keys()];
    return (
        <StyledCardWrapper >
            <StyledCardHeader>
                <h1>Bingo</h1>
            </StyledCardHeader>
            <StyledCard>
                <StyledGrid>
                    {MAX_ROWS.map((rowId) => {
                        return (
                            <StyledGridRow key={`row${rowId}`}>
                                {cardData[rowId].map((cell, index) => {
                                    return (
                                        <Cell
                                            key={`${rowId}${cell.value}${index}`}
                                            cell={cell}
                                            rowId={rowId}
                                            index={index}
                                        />
                                    );
                                })}
                            </StyledGridRow>
                        );
                    })}
                </StyledGrid>
            </StyledCard>
        </StyledCardWrapper>
    );
};

export default Card;
