import styled from 'styled-components';

export const StyledCardWrapper = styled.div`
    margin-top: 10%;
    background: #fff;
      display: inline-block;
      width: 100%;
      box-shadow: 0px 2.76726px 3.21002px rgba(0,0,0,0.0196802), 0px 6.6501px 7.71412px rgba(0,0,0,0.0282725), 0px 12.5216px 14.525px rgba(0,0,0,0.035), 0px 22.3363px 25.9101px rgba(0,0,0,0.0417275), 0px 41.7776px 48.462px rgba(0,0,0,0.0503198), -1px 5px 116px rgba(0,0,0,0.07);
`;

export const StyledCardHeader = styled.div`
    color: #000;
    text-transform: uppercase;
    font-size: 35px;
    letter-spacing: 1.64em;
    padding: 2% 0px;
    @media (max-width: 768px){
        font-size: initial;
    }
`;

export const StyledCard = styled.div`
    width: 100%;
    border: 1px solid black;
`;

export const StyledGrid = styled.div`
    display: block;
    width: 100%:
`;

export const StyledGridRow = styled.div`
    display: flex;
    width: 100%:
`;