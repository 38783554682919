import React, { useState } from 'react';
import { joinGame } from '../utils/FirebaseUtils';

const StartForm = ({ handleSetCards, handleSetGameCredentials }) => {
    const [text, setText] = useState({ gameCode: '', name: '', error: '' });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setText((prevState) => ({
            ...prevState,
            [name]: value.toUpperCase(),
        }));
    };

    const handleSubmit = (event) => {
        console.log('Sumbitting request to join a game');
        event.preventDefault();
        setText((prevState) => ({
            ...prevState,
            error: '',
        }));

        joinGame(text.gameCode, text.name)
            .then((cards) => {
                console.log(
                    `Player ${text.name} added to the game ${text.gameCode}`
                );

                handleSetGameCredentials(text);
                handleSetCards(cards);
            })
            .catch((error) => {
                console.log(`Error: ${error}`);
                setText((prevState) => ({
                    ...prevState,
                    error: error,
                }));
            });
    };

    return (
        <div className="form-container">
            <img
                alt="Lockdown Bingo"
                className="logo"
                src={'images/lockdown_bingo_logo.png'}
            />
            <form onSubmit={handleSubmit} onTouch={handleSubmit} id="home--login-form">
                <label>
                    Game code
                    <input
                        type="text"
                        name="gameCode"
                        value={text.gameCode}
                        placeholder="Enter your 5 letter code"
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Your name:
                    <input
                        type="text"
                        name="name"
                        value={text.name}
                        placeholder="Enter your name"
                        onChange={handleChange}
                    />
                </label>
                {text.error.length > 0 && <span>{text.error}</span>}
                <input type="submit" value="Join game" />
            </form>
        </div>
    );
};

export default StartForm;
