import styled from 'styled-components';

export const StyledCell = styled.div`
    text-align: center;
    user-select: none;
    background-color: ${props => props.isBlank ? '#dadada' : 'white'};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 42px 42px;
    width: calc(100%/9);
    line-height: 0;
    padding-top: 5%;
    padding-bottom: 5%;
    height: 0;
    display: inline-block;
    overflow: hidden;
    border: solid 1px #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
    position: relative;
    @media (max-width:768px){
        font-size: 16px;
    }
    z-index: +99999;
    &::after  {
        display: none;
        ${props => props.isSelected && 'display: block;' }
        content: '';
        width: 80px;
        height: 80px;
        margin: auto;
        background-size: cover;
        background-image: url('images/cellselect.png');
        ${props => props.isSelected && 'transform: rotate('+ Math.random()* 360 +'deg)translate('+ Math.random()* 5+'px,'+ Math.random()* 5+'px);'}
        opacity: 0.5;
        transform-origin: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
`;
