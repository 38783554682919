import { games as gamesRef } from '../services/firebase';
import { generateAStrip } from './CardGenerator';

export const checkACell = (gameCode, playerName, cardId, rowId, cellId, status) => {
    playerName = playerName.trim().toLowerCase().replace(/[^a-z]/ig, '');
    const cellRef = gamesRef.child(`${gameCode}/players/${playerName}/cards/${cardId}/${rowId}/${cellId}`);
    return new Promise((resolve, reject) => {
        cellRef.once('value', snapshot => {
            var cell = snapshot.val();
            if (cell) {
                console.log(`Cell row ${rowId} column ${cellId} of card ${cardId} player ${playerName} and game ${gameCode} found!`);
                cell.status = status;

                cellRef.set(cell).then(() => {
                    resolve(getPlayerCards(gameCode, playerName));
                });
            } else {
                reject(`Cell row ${rowId} column ${cellId} of card ${cardId} player ${playerName} and game ${gameCode} not found!`);
            }
        });
    });
};

export const joinGame = (gameCode, playerName) => {
    playerName = playerName.trim().toLowerCase().replace(/[^a-z]/ig, '');
    console.log(`${playerName} is joining the game...`);

    const gameRef = gamesRef.child(gameCode);
    const joinedGame = new Promise((resolve, reject) => {
        gameRef.once('value', snapshot => {
            if (snapshot.val()) {
                console.log(`Game found!`);
                const playerRef = gameRef.child('players/'+playerName);

                playerRef.once('value', snapshot => { 
                    let player = snapshot.val();
                    if (!player) {
                        console.log(`Player ${playerName} not found!`);
                        const player = initialiseAPlayer(playerName);       
                        playerRef.set(player).then(() => {
                            console.log(`Player ${playerName} created and saved!`);
                            resolve(getPlayerCards(gameCode, playerName));
                        });
                    } else {
                        console.log(`Player ${playerName} is found! ${snapshot.toJSON()}`);
                        resolve(getPlayerCards(gameCode, playerName));                        
                    }
                })
            } else {
                reject(`Game with gameCode ${gameCode} does not exist`);
            }
        });
    });

    return joinedGame;
};

export const getPlayerCards = (gameCode, playerName) => {
    playerName = playerName.trim().toLowerCase().replace(/[^a-z]/ig, '');
    console.log(`Retrieving cards for ${playerName}...`);

    return new Promise((resolve, reject) => {
        const playerRef = gamesRef.child(gameCode + '/players/' + playerName);
        playerRef.once('value', snapshot => {
            const player = snapshot.val();
            if (player) {
                console.log('player', player);
                console.log('player cards', player.cards);
                resolve(player.cards);
            } else {
                reject(`Player ${playerName} not found!`);
            }
        });
    });
}

function initialiseAPlayer(playerName) {
    return {
        joined: Date.now(), 
        cards: generateAStrip() };
}