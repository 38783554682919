import React, { useContext, useState } from 'react';
import { StyledCell } from './Cell.style';
import { checkACell } from '../../utils/FirebaseUtils';
import CellConstants from '../../constants/CellConstants.json';
import GameContext from '../../context/GameContext';

const Cell = ({ cell, rowId, index }) => {
    const [isSelected, setSelected] = useState(cell.status === CellConstants.status.selected);
    const isBlank = cell.value === CellConstants.value.blank;
    const credentials = useContext(GameContext);
    // to access the data: credentials.gameCode / credentials.name

    const handleClick = () => {
        if (isBlank) return;

        cell.status = cell.status === CellConstants.status.selected ? CellConstants.status.unselected : CellConstants.status.selected;
        setSelected(cell.status === CellConstants.status.selected);
        checkACell(credentials.gameCode, credentials.name, 0, rowId, index, cell.status);

    };

    return (
        <StyledCell
            onClick={handleClick}
            onTouch={handleClick}
            isBlank={isBlank}
            isSelected={isSelected}
        >
            <span>{isBlank ? '' : cell.value}</span>
        </StyledCell>
    );
};

export default Cell;
