import React, { useState } from 'react';
import GameContext from './context/GameContext';
import { MainWrapper, GlobalStyle } from './App.style';
import { ViewForm, ViewCard } from './pages';

const App = () => {
    const isLocal = true; // remove later on
    const [cards, setCards] = useState(null);
    const [gameCredentials, setGameCredentials] = useState({
        gameCode: '',
        name: '',
    });

    const handleSetCards = (newCards) => {
        setCards(newCards);
    };

    const handleSetGameCredentials = (credentials) => {
        setGameCredentials(credentials);
        setGameCredentials({
            gameCode: credentials.gameCode,
            name: credentials.name,
        });
    };

    return (
        <GameContext.Provider value={gameCredentials}>
            <MainWrapper
                id={cards === null && !isLocal ? 'page-home' : 'page-card'}
            >
                <GlobalStyle />
                {cards === null || !cards.length > 0 ? (
                    <div className="container">
                        <ViewForm
                            handleSetCards={handleSetCards}
                            handleSetGameCredentials={handleSetGameCredentials}
                        />
                    </div>
                ) : (
                    <div className="container" id="view-card">
                        <ViewCard cardData={cards[0]} />
                    </div>
                )}
            </MainWrapper>
        </GameContext.Provider>
    );
};

export default App;
