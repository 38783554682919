// import * as firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAfCLyE1HV2ZKBLhB6id454ZZW0MWUTvuM",
  authDomain: "bingo-cards-364ae.firebaseapp.com",
  databaseURL: "https://bingo-cards-364ae.firebaseio.com",
  projectId: "bingo-cards-364ae",
  storageBucket: "bingo-cards-364ae.appspot.com",
  messagingSenderId: "101715565116",
  appId: "1:101715565116:web:0ffefc7c92b04793020139",
  measurementId: "G-1ZLDKQRS8S"
};

firebase.initializeApp(firebaseConfig);
export const games = firebase.database().ref('games');