import styled, { createGlobalStyle }  from 'styled-components';

export const MainWrapper = styled.div`
  text-align: center;
  height: 100%;
`;

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
  h1 {
    margin: 0;
  }
  body {
    background-color: #fff;
    font-family: 'Poppins', sans-serif !important;
    margin: 0 0;
  }
  .container {
    max-width: 1040px;
    margin: auto;
  }
  #page-home {
    background-color: #ebf0f5;
    .container {
      height: 100%;
      display: flex;
      align-items:center;
    }
  }
  .form-container {
    width: 100%;
    max-width: 440px;
    display: block;
    margin: auto;
    .logo {
      width: 218px;
      margin-bottom: 32px;
    }
    #home--login-form {
      background-color: #fff;
      padding: 0px 25px 25px 20px;
      border-radius: 10px;
      box-shadow: 0px 3.09933px 2.21381px rgba(146, 146, 146, 0.0253031), 0px 7.44811px 5.32008px rgba(146, 146, 146, 0.0363503), 0px 14.0241px 10.0172px rgba(146, 146, 146, 0.045), 0px 25.0167px 17.869px rgba(146, 146, 146, 0.0536497), 0px 46.7909px 33.4221px rgba(146, 146, 146, 0.0646969), 0px 112px 80px rgba(146, 146, 146, 0.09);
      label {
        font-weight: 500;
        padding-top: 32px;
        display: block;
        text-transform: uppercase;
      }
      input {
        box-sizing:border-box;
        margin-top: 12px;
        text-align: center;
        width: 100%;
        height: 48px;
        display block;
        font-size: 15px;
        cursor: pointer !important;
        :focus {
          outline: none !important;
        }
      }
      input[type="submit"] {
        height: 50px;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        background-color: #26cdff;
        color: #fff;
        border-radius: 200px;
        border: none;
        font-size: 15px;
        margin-top: 30px;
        :hover {
          background-color: #125d73;
        }
      }
    }
  }
`